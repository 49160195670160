import { Link } from "react-router-dom";

export default function Footer() {
  return (
    <>
      <div className="footer">
        <p>BACKYARD BIODIVERSITY  &nbsp;&nbsp;&bull; &nbsp;&nbsp; START ANYWHERE  &nbsp;&nbsp;&bull; &nbsp;&nbsp; LEARN ABOUT POWER  &nbsp;&nbsp;&bull; &nbsp;&nbsp; THC INSECT REPELLENT  &nbsp;&nbsp;&bull; &nbsp;&nbsp; ANIMAL SOUL  &nbsp;&nbsp;&bull; &nbsp;&nbsp; <span className="nht"><Link to="/human-encounters">FIND NONHUMAN TEACHERS</Link></span> &nbsp;&nbsp;&bull; &nbsp;&nbsp; BACKYARD BIODIVERSITY  &nbsp;&nbsp;&bull; &nbsp;&nbsp; START ANYWHERE  &nbsp;&nbsp;&bull; &nbsp;&nbsp; LEARN ABOUT POWER  &nbsp;&nbsp;&bull; &nbsp;&nbsp; THC INSECT REPELLENT  &nbsp;&nbsp;&bull; &nbsp;&nbsp; ANIMAL SOUL  &nbsp;&nbsp;&bull; &nbsp;&nbsp; <span className="nht"><Link to="/human-encounters">FIND NONHUMAN TEACHERS</Link></span> &nbsp;&nbsp;&bull; &nbsp;&nbsp; BACKYARD BIODIVERSITY  &nbsp;&nbsp;&bull; &nbsp;&nbsp; START ANYWHERE  &nbsp;&nbsp;&bull; &nbsp;&nbsp; LEARN ABOUT POWER  &nbsp;&nbsp;&bull; &nbsp;&nbsp; THC INSECT REPELLENT  &nbsp;&nbsp;&bull; &nbsp;&nbsp; ANIMAL SOUL  &nbsp;&nbsp;&bull; &nbsp;&nbsp; <span className="nht"><Link to="/human-encounters">FIND NONHUMAN TEACHERS</Link></span> &nbsp;&nbsp;&bull; &nbsp;&nbsp; BACKYARD BIODIVERSITY  &nbsp;&nbsp;&bull; &nbsp;&nbsp; START ANYWHERE  &nbsp;&nbsp;&bull; &nbsp;&nbsp; LEARN ABOUT POWER  &nbsp;&nbsp;&bull; &nbsp;&nbsp; THC INSECT REPELLENT  &nbsp;&nbsp;&bull; &nbsp;&nbsp; ANIMAL SOUL  &nbsp;&nbsp;&bull; &nbsp;&nbsp; <span className="nht"><Link to="/human-encounters">FIND NONHUMAN TEACHERS</Link></span> &nbsp;&nbsp;&bull; &nbsp;&nbsp; </p>
      </div>
    </>
  );
}