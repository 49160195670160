const videoList = [
  { title: "The fungus Aspergillus niger growing fruiting bodies at 10x magnification. Video by Wim van Egmond.", link: "https://i.imgur.com/FDT2jp5.mp4" },
  { title: "Chromataphores in squid mantle at 4x magnification. Video by Bill Shin.", link: "https://i.imgur.com/lSpFsxe.mp4" },
  { title: "Green Stentor coeruleus and Vorticella at 25x magnification. Video by Frank Fox.", link: "https://i.imgur.com/ENiRikD.mp4" },
  { title: "A ciliate from genus Pseudomicrothorax devours a cyanobacterial filament at 20x and 40x magnification. Video by Dr. Sally Warring.", link: "https://i.imgur.com/cTQqlEk.mp4" },
  { title: "The rotifer Limnias melicerta at 200x magnification. Video by Wim van Egmond.", link: "https://i.imgur.com/iIntJym.mp4" },
  { title: "Polychaete worm of the Syllidae family at 20x and 40x magnification. Video by Rafael Martín-Ledo.", link: "https://i.imgur.com/R4UIzum.mp4" },
  { title: "Lightning strike on the coast of Montenegro", link: "http://i.imgur.com/XiM9dRh.mp4" },
  { title: "Hawkmoth", link: "https://i.imgur.com/NudotTQ.mp4" },
  { title: "Friendly deer", link: "https://i.imgur.com/vJ5PXiR.mp4" },
  { title: "mini horse", link: "https://i.imgur.com/oXV1dQ1.mp4" },
  { title: "opossums", link: "https://i.imgur.com/QtoenRl.mp4" },
  { title: "cracking geode", link: "https://i.imgur.com/LO3T7QN.mp4" },
  { title: "swimming crab", link: "https://05ec48578e12534030.temporary.link/nht/videos/swimming-crab.mp4" },
  { title: "snail", link: "https://05ec48578e12534030.temporary.link/nht/videos/snail.mp4" },
  { title: "sunset", link: "https://05ec48578e12534030.temporary.link/nht/videos/sunset.mp4" },
  { title: "high voltage", link: "https://05ec48578e12534030.temporary.link/nht/videos/high-voltage.mp4" },
  { title: "rafflesia", link: "https://05ec48578e12534030.temporary.link/nht/videos/rafflesia.mp4" },
  { title: "mower", link: "https://05ec48578e12534030.temporary.link/nht/videos/mower.mp4" },
  { title: "matrix", link: "https://05ec48578e12534030.temporary.link/nht/videos/matrix.mp4" },
  { title: "horses farting", link: "https://05ec48578e12534030.temporary.link/nht/videos/horses-farting.mp4" },
  { title: "sea slugs", link: "https://05ec48578e12534030.temporary.link/nht/videos/sea-slug.mp4" },
  { title: "elephants crushing pumpkins", link: "https://05ec48578e12534030.temporary.link/nht/videos/elephant-pumpkin.mp4" },
  // { title: "", link: "" },
];

export default videoList;